import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';
import 'tilt.js';
import Parallax from 'parallax-js';
import TweenLite from "gsap/TweenLite";

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );
var imagesloaded = require('imagesloaded');
jQueryBridget( 'imagesLoaded', imagesloaded, $ );

window.$ = window.jQuery = $;

let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.swiper();
            Page.initialize.tilt();
            Page.initialize.magnificPopup();
            Page.initialize.calc()
        },

        /* ---------------------------------------------
                    magnificPopup
                --------------------------------------------- */
        calc: function() {
            let $target = $(".container-no-right-margin");
            if ($target.length) {
                update($target);
                $(window).on('resize', function () {
                    update($target);
                });

                function update($target) {
                    if($(window).width() > 992) {
                        var width = $(".container").outerWidth() + $(".container .row").position().left - 40;
                        $target.css("width", width);
                    }
                    else {
                        $target.css("width", '');
                    }
                }
            }
        },

        /* ---------------------------------------------
                    magnificPopup
                --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },

        /* ---------------------------------------------
            Tilt
        --------------------------------------------- */
        tilt: function(){

            if ( $(window).width() > 1199 ){

                $('.js-tilt-hover').tilt({
                    glare: true,
                    maxTilt: 8,
                    maxGlare: .2,
                });

                $('.js-tilt-hover-sm').tilt({
                    glare: false,
                    maxTilt: 2,
                });
            }
        },
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            // swiper hero
            if ( $('#swiper-hero').length > 0 ) {

                let thumbnails = new Swiper('#swiper-thumbnail', {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    loop: true,
                    loopedSlides: 1,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                    allowTouchMove: false,
                    slideToClickedSlide: true,
                    freeMode: true,
                    //centeredSlides: true,
                });
                let swiperHero = new Swiper('#swiper-hero', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,
                    loopedSlides: 1,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    speed: 1700,
                    thumbs: {
                        swiper: thumbnails,
                    },
                });

                var scene = $('#swiper-hero').get(0);
                var parallax = new Parallax(scene, {
                    selector: '.hero-background'
                });
            }

            if ( $('.section.section-realizations .tab-content .tab-pane').length > 0 ) {
                var tabs = $(".section.section-realizations .tab-content .tab-pane");
                tabs.each(function (i, v) {
                    var $this = $(v);
                    new Swiper('.swiper-realization-' + $this.attr("id"), {
                        // effect: 'fade',
                        slidesPerView: 4.65,
                        slidesPerGroup: 1,
                        spaceBetween: 20,
                        simulateTouch: true,
                        observer: true,
                        observeParents: true,
                        // touchRatio: 0,
                        loop: true,
                        slideToClickedSlide: true,
                        allowTouchMove: true,
                        uniqueNavElements: false,
                        preventInteractionOnTransition: true,
                        speed: 1200,
                        navigation: {
                            nextEl: $(this).find('.swiper-navigation').find('.swiper-button-next'),
                            prevEl: $(this).find('.swiper-navigation').find('.swiper-button-prev'),
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            576: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1440: {
                                slidesPerView: 3,
                            },
                        }
                    });
                });
            }
            if ( $('.swiper-gallery').length > 0 ) {
                new Swiper('.swiper-gallery', {
                    // effect: 'fade',
                    slidesPerView: 3,
                    spaceBetween: 20,
                    simulateTouch: true,
                    loop: true,
                    slideToClickedSlide: true,
                    allowTouchMove: true,
                    uniqueNavElements: false,
                    preventInteractionOnTransition: true,
                    speed: 1200,
                    navigation: {
                        nextEl: $(".swiper-gallery").find('.swiper-navigation').find('.swiper-button-next'),
                        prevEl: $(".swiper-gallery").find('.swiper-navigation').find('.swiper-button-prev'),
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        576: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }
                });
            }
        },

        
        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                //disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 150, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    console.log('click')
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || 0;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $(".toggle-menu");
            let scroll_amount = 122;

            
            //menu
            $toggleMenu.click(function(){
                $("#toggle-menu").toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });
            //menu
            $("#offcanvas .has-megamenu").click(function(){
                $(this).toggleClass('active');
            });

            if ( $window.scrollTop() > scroll_amount ){
                $body.addClass("page-scroll");
            }

            $window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    $body.addClass("page-scroll");
                } else{
                    $body.removeClass("page-scroll");
                }
            });
        },
    };
})(jQuery);





